






























































import Vue from 'vue';
import { namespace } from 'vuex-class';
import './scss/PopupDocumentsSignature.scss';
import { Component } from 'vue-property-decorator';
import { IUserState } from '@store/modules/user/Interfaces';
import { IFile } from '@store/modules/project-documents/Interfaces';
import { ProjectDocumentsActions } from '@store/modules/project-documents/Types';
import { Certificate, createDetachedSignature, createHash, getUserCertificates, isValidSystemSetup } from 'crypto-pro';
import http from '@/http';

const NSUser = namespace('storeUser');
const NSProject = namespace('storeProject');
const NSDocuments = namespace('storeProjectDocuments');

@Component({
    name: 'PopupDocumentsSignature',
    components: {
        BasePopup: () => import('@components/popups/BasePopup.vue'),
        BaseSelect: () => import('@components/BaseSelect/BaseSelect.vue'),
    }
})

export default class PopupDocumentsSignature extends Vue {
    $refs!: {
        basePopup: HTMLFormElement,
    }

    @NSUser.Getter('userData') userData!: IUserState;
    @NSProject.Getter('projectId') projectId!: number;
    @NSDocuments.Action(ProjectDocumentsActions.A_SIGN_DOCUMENT) signDocument!: (data) => Promise<void>

    documentsList: any[] = [];
    selectedFiles: IFile[] = [];
    signLoading: boolean = false;
    noFiles: boolean = false;

    certs: Certificate[] = [];
    pluginLoading: boolean = false;
    noCertLoad = false;
    certThumbprint: Certificate | null = null;

    signsAndIdsOfFiles: any[] = [];
    packPDSignData: any = null;

    filesForSigning: File[] = [];

    get certsFormatted() {
        return this.certs.map(item => {
            return {
                label: item.name,
                ...item,
            }
        });
    }

    get placeholderForCertificateSelect() {
        return this.certThumbprint ? this.certThumbprint.name : 'Выбор сертификата для подписания';
    }

    get btnDisabled() {
        return !this.selectedFiles?.length || !this.certThumbprint || this.signLoading;
    }

    openPopup() {
        if (this.$refs.basePopup) {
            this.$refs.basePopup.openPopup();
            setTimeout(() => {
                if (!this.certs.length) {
                    this.loginPlugin();
                }
            }, 1)
        }
    }

    closePopup() {
        this.$refs.basePopup.closePopup();
        this.onClose();
    }

    onClose() {
        this.documentsList = [];
        this.selectedFiles = [];
    }

    onSuccess() {
        if (!this.selectedFiles?.length) {
            return null;
        }
        this.startSign();
    }

    setData(data) {
        this.selectedFiles = [];
        this.documentsList = data;
        data.forEach(document => {
            this.selectedFiles = this.selectedFiles.concat(document.files);
        });
        this.$nextTick(() => {
            this.noFiles = this.selectedFiles.length === 0;
        });
    }

    setPackPDSignData(data) {
        this.packPDSignData = data;
    }

    readFile(item) {
        return new Promise((resolve) => {
            const fileReader = new FileReader();
            fileReader.onload = function () {
                resolve(this.result);
            };
            http.get(item.linkView, {responseType: 'blob'})
                .then((res) => {
                    fileReader.readAsArrayBuffer(res.data)
                })
        });
    }

    async startSign() {
        this.signLoading = true;
        try {
            this.filesForSigning = JSON.parse(JSON.stringify(this.selectedFiles));
            if (this.packPDSignData) {
                await this.signPackPD();
            }
            await this.signFile(this.filesForSigning[0], this.packPDSignData === null);
        } catch (error) {
            console.error(error);
        }
    }

    base64ToArrayBuffer(base64) {
        const binaryString = atob(base64);
        const bytes = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes.buffer;
    }

    async signFile(item, useSetSignMethod = false): Promise<void> {
        const message = await this.readFile(item);
        const hash = await createHash(message as ArrayBuffer);
        if (this.certThumbprint) {
            const signaturePromise = createDetachedSignature(this.certThumbprint.thumbprint, hash);
            signaturePromise.then((signature) => {
                if (item && item.uuid) {
                    if (useSetSignMethod || (!useSetSignMethod && this.packPDSignData.filesIds.includes(item.uuid))) {
                        this.signsAndIdsOfFiles.push({
                            id: item.uuid,
                            sign: signature,
                        });
                    }
                    if (useSetSignMethod) {
                        this.signDocument({
                            sign: signature,
                            appId: this.projectId,
                            userId: this.userData.userInfo.id,
                            fileId: item.uuid
                        })
                    }
                    this.filesForSigning.splice(0, 1);
                    if (this.filesForSigning.length) {
                        this.signFile(this.filesForSigning[0], useSetSignMethod);
                    } else {
                        this.afterSign();
                    }
                }
            })
        }
    }

    async signPackPD() {
        const hash = await createHash(this.base64ToArrayBuffer(this.packPDSignData.fileData));
        if (this.certThumbprint) {
            const sign = await createDetachedSignature(this.certThumbprint.thumbprint, hash);
            if (this.packPDSignData && this.packPDSignData.id) {
                this.signsAndIdsOfFiles.push({
                    id: this.packPDSignData.id,
                    sign,
                });
            }
        }
    }

    async loginPlugin() {
        this.pluginLoading = true;
        try {
            if (!await isValidSystemSetup()) {
                throw new Error('no valid settings');
            }
            let certs = await getUserCertificates();
            this.certs = certs;
        } catch (e) {
            this.noCertLoad = true;
            console.error(e.message);
        } finally {
            this.pluginLoading = false;
        }
    }

    afterSign() {
        this.closePopup();
        this.$emit('signed', this.signsAndIdsOfFiles);
        this.packPDSignData = null;
        this.signsAndIdsOfFiles = [];
        this.filesForSigning = [];
    }
}
